$fontSize: 14px;
$bodyBgColor: #edf0f5;
$textColor: #333333;
$textSecondaryColor: #707070;
$borderRadius: 3px;
$dividerColor: #e3e3e3;
$transitionDuration: 0.2s;
$maskBgColor: #424242;

/* Menu */
$scrollPanelBgColor: #aaaaaa;
$menuitemBadgeBgColor: #007be5;
$menuitemBadgeColor: #ffffff;
$submenuFontSize: 13px;

/* Menu Dark*/
$menuDarkBgColorFirst: var(--menu-font-color);
$menuDarkBgColorLast: var(--menu-font-color);
$menuitemDarkColor: var(--menu-font-color);
$menuitemDarkHoverColor: var(--menu-font-hover-color);
$menuitemDarkActiveColor: var(--menu-font-hover-color);
$menuitemDarkActiveBgColor: var(--menu-active-bg-color);
$menuitemDarkBorderColor: var(--menu-active-border-color);

/* Menu Light*/
$menuBgColorFirst: #f3f4f9;
$menuBgColorLast: #d7dbe8;
$menuitemColor: #232428;
$menuitemHoverColor: #0388e5;
$menuitemActiveColor: #0388e5;
$menuitemActiveBgColor: var(--main-color-0);
$menuitemBorderColor: var(--menu-active-border-color);

/* Topbar */
$topbarLeftBgColor: var(--main-color-0);
$topbarRightBgColor: var(--main-color-0);
$topbarItemBadgeBgColor: #fd1c1c;
$topbarItemBadgeColor: #fd1c1c;
$topbarItemColor: var(--top-font-color);
$topbarItemHoverColor: var(--hover-color);
$topbarSearchInputBorderBottomColor: #e61414;
$topbarSearchInputColor: #d41313;

/* Footer */
$footerBgColor: #ffffff;

.show-menu-item {
  display: block;
}
.hide-menu-item {
  display: none;
}

.darken-overlay div {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.sidebganimated {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 1s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@import 'content/theme/theme.css';
