html {
  font-size: 14px;
}

/* Outer-ring */
// #chart_div circle:nth-child(1) {
//   stroke-width: 5;
//   stroke: rgb(5, 219, 22);
//   fill: rgb(5, 218, 16);
// }

.custom-gauge circle:nth-child(1) {
  stroke-width: 5;
  stroke: var(--main-color-1);
  fill: var(--main-color-0);
}

// /* Main background */
// #chart_div circle:nth-child(2) {
//   fill: rgb(0, 0, 0);
//   stroke: #ddd;
//   stroke-width: 5;
// }

// /* Circle of the pointer */
// #chart_div circle:nth-child(3) {
//   fill: pink;
//   stroke: white;
//   stroke-width: 6px;
// }

/* Main text of the gauge */
.custom-gauge text {
  font-family: 'Comfortaa';
  font-size: 1.1rem;
}

.wifi {
  background-image: url('~/public/static-images/wifi.png');
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
}

.wifi0 {
  background-image: url('~/public/static-images/wifi0.png');
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
}

.wifi1 {
  background-image: url('~/public/static-images/wifi1.png');
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
}

.wifi2 {
  background-image: url('~/public/static-images/wifi2.png');
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
}

.wifi3 {
  background-image: url('~/public/static-images/wifi3.png');
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
}

.toast-success-container {
  color: #000 !important;
  border-radius: 8px !important;
  background: rgb(255, 255, 255) !important;
  border: 4px solid var(--toast-success) !important;
  box-shadow: 3px 3px 3px var(--toast-success) !important;
  text-align: center;
}

.toast-success-progress-bar {
  background: var(--toast-success) !important;
}

.toast-success-container-after {
  overflow: hidden;
  position: relative;
  height: inherit;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row-accessories {
  background-color: rgba(54, 54, 54, 0.178) !important;
}

.datascroller-demo .product-name {
  font-size: 1.5rem;
  font-weight: 700;
}

.datascroller-demo .product-description {
  margin: 0 0 1rem 0;
}

.datascroller-demo .product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.datascroller-demo .product-category {
  font-weight: 600;
  vertical-align: middle;
}

.datascroller-demo .product-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.datascroller-demo .product-item img {
  width: 150px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-right: 2rem;
}

.datascroller-demo .product-item .product-detail {
  flex: 1 1 0;
}

.datascroller-demo .product-item .p-rating {
  margin: 0 0 0.5rem 0;
}

.datascroller-demo .product-item .product-price {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  align-self: flex-end;
}

// .datascroller-demo .product-item .product-action {
//   display: flex;
//   flex-direction: column;
// }

.datascroller-demo .product-item .p-button {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 576px) {
  .datascroller-demo .product-item {
    flex-direction: column;
    align-items: center;
  }

  .datascroller-demo .product-item img {
    width: 75%;
    margin: 2rem 0;
  }

  .datascroller-demo .product-item .product-detail {
    text-align: center;
  }

  .datascroller-demo .product-item .product-price {
    align-self: center;
  }

  // .datascroller-demo .product-item .product-action {
  //     // display: flex;
  //     // flex-direction: column;
  // }

  // .datascroller-demo .product-item .product-action {
  //     margin-top: 0rem;
  //     flex-direction: row;
  //     justify-content: space-between;
  //     align-items: center;
  //     width: 100%;
  // }
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: var(--top-font-color);
  }
  40% {
    stroke: var(--top-font-color);
  }
  66% {
    stroke: var(--top-font-color);
  }
  80%,
  90% {
    stroke: var(--top-font-color);
  }
}
.card {
  background-color: white;
  padding: 10px 20px 11px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}
.pay-button {
  padding: 0.7rem 2rem;
  width: 100%;
  margin: 1rem 0;
  color: white;
  font-weight: bold;
  font-size: medium;
  background-color: #556cd6;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}
.pay-button:disabled {
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}
.pay-button:disabled:hover {
  box-shadow: none;
}
.pay-button:hover {
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}

$pvStringNormal: #068a48;
$pvStringLow: #f95151;
$pvStringHigh: #1100ff;
$pvStringZero: #797979;

@import './content/theme/theme.scss';
@import './content/css/loading.css';
@import './content/css/flags.css';
@import './content/scss/sass/_layout';
@import '~primereact/resources/primereact.min.css';
@import '~primeicons/primeicons.css';
@import '~primeflex/primeflex.css';
