@keyframes lds-pacman-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@-webkit-keyframes lds-pacman-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes lds-pacman-2 {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@-webkit-keyframes lds-pacman-2 {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes lds-pacman-3 {
  0% {
    -webkit-transform: translate(190px, 0);
    transform: translate(190px, 0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(70px, 0);
    transform: translate(70px, 0);
    opacity: 1;
  }
}
@-webkit-keyframes lds-pacman-3 {
  0% {
    -webkit-transform: translate(190px, 0);
    transform: translate(190px, 0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(70px, 0);
    transform: translate(70px, 0);
    opacity: 1;
  }
}

.app-loading {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 10em;
}
.app-loading p {
  display: block;
  font-size: 1.17em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: normal;
}

.app-loading .lds-pacman {
  position: relative;
  margin: auto;
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}
.app-loading .lds-pacman > div:nth-child(2) div {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 120px;
  height: 60px;
  border-radius: 120px 120px 0 0;
  background: #bbcedd;
  -webkit-animation: lds-pacman-1 1s linear infinite;
  animation: lds-pacman-1 1s linear infinite;
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
}
.app-loading .lds-pacman > div:nth-child(2) div:nth-child(2) {
  -webkit-animation: lds-pacman-2 1s linear infinite;
  animation: lds-pacman-2 1s linear infinite;
}
.app-loading .lds-pacman > div:nth-child(1) div {
  position: absolute;
  top: 92px;
  left: 0px;
  width: 20px;
  height: 20px;
  background-image: url('~/public/static-images/favicon.png');
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-animation: lds-pacman-3 1s linear infinite;
  animation: lds-pacman-3 1.5s linear infinite;
}
.app-loading .lds-pacman > div:nth-child(1) div:nth-child(1) {
  -webkit-animation-delay: -0.67s;
  animation-delay: -1s;
}
.app-loading .lds-pacman > div:nth-child(1) div:nth-child(2) {
  -webkit-animation-delay: -0.33s;
  animation-delay: -0.5s;
}
.app-loading .lds-pacman > div:nth-child(1) div:nth-child(3) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
